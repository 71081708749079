@import (reference) "braeburn-references.less";

@media (max-width: 1100px) {
   .cart-stock-status {
        margin-left: 35px;
    }

   /* IPAD HORIZONTAL */

   .choice-image {
       img {
           max-width:200px;
       }
   }

    .navbar-brand img {
        max-width: 100px;
        margin-top: 13px;
    }

    .navbar .widget-search-form {
        margin-left: 10px;
        float: none;
    }
}

@media (max-width: @screen-md-min) {
    .container {
        width: 100%;
    }

    .cart-product-image {
        margin-bottom: 10px;
    }

    .cart-product-top-row div {
        padding-bottom: 3px;
    }

   .cart-stock-status {
        margin-left: 0;
        padding: 0;
    }

    .col-md-3 .well {
        margin-right: 30px;
    }

    aside {
        clear: both;
    }

    .label-text-align {
        text-align: left;
    }
    /*KINDLE FIRE*/
}

@media (min-width: @screen-md-min) {
    .container {
        width: 100%;
    }
}

@media (max-width: @screen-sm-min) {
    .form-inline select.form-control {
        width: 100%;
    }

    .comment-form .comments-1 input.btn-primary {
        float: none;
    }

    .col-md-3 {
        margin-left: 0;
        margin-right: 0;
    }

    .add-to-cart {
        width: 200px;
        float: left;
        margin-left: 15px;
        margin-bottom: 10px;
        margin-top: 0;
    }

    .product-description {
        padding-left: 15px;
    }

    .checkout-chain {
        display: none;

        li.active {
            background: @ciderButtonsAndLinksLight;
        }

        li.active + li {
            background: @ciderButtonsAndLinks;
        }

        li.active.first {
            background: @ciderButtonsAndLinksLight;
        }
    }

    .product-details {
        max-width: none;

        .add-to-cart {
            float: left;
            width: 100%;
        }

        h3 {
            max-width: none;
        }
    }

    .product-summary {
        width: 100%;
        margin-bottom: 3px;
        float: left;
    }

    .content-items {
        * {
            .content-item {
                margin-top: 50px;
                padding: 0;

                header {
                    margin-bottom: 5px;
                    margin-left: 0 !important;
                    max-width: none;
                    float: none;
                }

                .product-details {
                    p {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .content-item {
        .product-image {
            margin-top: 0;
        }

        .image-frame {
            max-width: 40%;
            padding: 0;
            margin: 10px 0 20px 15px;
            border: none;
            box-shadow: none;

            img {
                max-width: 100%;
            }
        }

        .product-add-to-cart, & > .add-to-cart {
            max-width: 50%;
            border: none;
            box-shadow: none;
            padding: 0;
            margin: 0 0 20px 0;
            float: right;

            .item-price-label {
                font-size: 12px;
                padding: 6px 0 6px 5px;
            }
        }
    }

    aside {
        .well {
            float: none !important;
        }
    }

    .account-box {
        .account-group-col {
            padding: 0;

            &.last {
                padding: 0;
            }

            &.first {
                padding: 0;
            }
        }
    }

    .cart-product-detail {

        h3 {
            margin-top: 0;
        }
    }

    .choice-image {
        img {
            max-width: 250px;
        }
    }

    .mobile-full-height {
        height: 100%;
    }

    /*IPAD VERTICAL*/
}

/* Collapsed menu */
@media (max-width: @menu-collapse-width) {
    .navbar {
        margin-left: 10px;

        .navbar-form.navbar-right:last-child {
            margin-top: 17px;
        }

        .navbar-nav {
            float: none !important;
            margin: 0 !important;
        }

        .widget-search-form {
            width: 100%;
            margin-left: 0;
        }

        .widget-cart-widget {
            float: none;
            padding: 10px 0;

            .dropdown-bar {
                margin-top: 0;
                margin-bottom: 0;
            }

            .cart-count {
                width: 100%;
            }

            .mini-cart-area {
                display: none !important;
            }
        }
    }
}

@media (max-width: @screen-xs-max) {
    body.fixed-nav {
        padding-top: 88px;
    }

    .search-form {
        .input-group {
            padding-bottom: 5px;
            padding-top: 15px;
        }
    }
    .edit-participants-row {
        margin-bottom: 15px;
    }
}

@media(max-width:400px) {

    .vcard {
        width: 100% !important;
        border: none;
        padding: 18px;
        border-radius: 15px;
    }

    .vcard .email, .vcard .tel, .vcard .adr {
        font-size: 13px;
    }

    #remove-ship-to{
        padding-right: 0;
        padding-left: 35px;
        float:none;
    }

    .arrow {
        width: 13%;
        padding-top: 50%;
    }

    .item-picker {
        width: 100%;
    }

    .zone-content {
        margin: 3px;
        margin-top: 5px;
    }

    .col-md-3 {
        margin-left: 0;
        margin-right: 0;
    }

    #footer {
        margin-left: -20px;
        padding-left: 20px;
        background:@colorNavbar;
    }

    .btn-primary.update-cart {
        width: 100%;
    }

    .col-sm-2.checkout-button {
        padding-right: 15px;
    }

    .credit-card {
        padding: 10px;
        width: 270px;

         .control-label.cc-expiration-label {
            padding-left: 1px;
         }
    }

    h2 {
        margin-top: 0;
    }

    .order-detail-item {
        .product-list {
            width: auto;
        }
    }

    .container {
        padding-right: 30px;
        padding-left: 30px;
        margin-right: auto;
        margin-left: auto;
    }

    .row.prime {
        padding-top: 30px;
    }

    body.fixed-nav {
        padding-top: 60px;
    }

    .product-details {
        h3 {
            padding-top: 0;
        }
    }

    .inventory-status {
        padding-left: 15px;
        padding-bottom: 15px;
    }

    hr {
        border-top: 1px solid #aaaaaa;
    }

    .title-box {
        margin-top: 20px;
        margin-left: 0;
        margin-right: 0;
    }

    .payment-information {
        margin-top: 25px;
    }

    .product-list-widget-title {
        h3 {
            margin-top: 10px;
        }
    }

    .quantity-editor{
        width:77%;

        b, input, button, small{
            float:left;
        }

        input{
            margin-left:6px;
        }

        button{
            margin-top:1px;
            margin-right:3px;
        }
       
    }

    .unselect-all{
        overflow:auto;
        padding-left:0;
    }

    .carousel {
        margin-bottom: 0;
    }
     
    .popup-image.ui-dialog-content {
        img {
            max-width: 300px;
            border-radius: 0;
        }

        padding: 0;
        overflow: hidden;
    }

    .ui-dialog {
        padding: 0;
    }

    .col-md-3 .well {
        margin-left: 15px;
        margin-right: 15px;
    }

    .product-description {
        width: 100%;
    }

    .shopping-cart-page {
        margin-right: 0;
    }

    .confirm-renew-changes {
        margin: 10px;
        padding: 10px;

        .btn {
            margin-top: 10px;
            width: 200px;
        }
    }

    .csc-code {
        width: 50%;
    }

    .btn-group {
        margin-left: 0;
        width:100%;

        .btn {
            width:50%;
            margin-right:0;
        }

        .btn-primary {
            white-space: normal;
            height: 100%;
        }
    }

    .shopping-cart-button {
        .dropdown-bar {
            width: auto;
        }
    }

    .iPhoneCartButtonOffset {
        max-width: none;
        margin-left: 0;
    }

    .cart-button {
        .iPhoneCartButtonOffset;
    }

    .cart-money {
        .iPhoneCartButtonOffset;
    }

    .label-text-align {
        text-align:left;
    }

    .card-contents{
        max-width: 185px;
    }

    .product-list{
        img{
            max-width: 50px;
        }
    }

    .promotion-narrative{
        .mag{
            max-width: 30%;
        }
    }

    .promotion-disclaimer{
        .mag{
            max-width: 30%;
        }
    }

    .form-control.email-list-address {
        font-size: 12px;
    }

    .zone-messages{
        margin:0;
    }

    .expire select{
        width: 100%;
        float: left;
        margin:0;
        padding: 5px;
    }

    .expire .expire-month select, .expire .expire-year select {
        padding:0;
    }

    input[type="radio"], input[type="checkbox"] {
        height:auto;
    }

    /*IPHONE SECTION END*/
}
@import (reference) "braeburn-references.less";

.pager {
    // Bootstrap uses the pagination class. We used to have typescript that would run on document ready
    // to swap the pager class for the pagination class. Instead of that, just make the pager class styled
    // like the pagination class.
    .pagination()
}

body {
    padding-top: 0;
    background: @colorBodyBackground;
    color: @colorBodyText;
    font-size: @bodyFontSize;

    @media (max-width: @screen-xs-max) {
        font-size: 13px;
    }
}

body.fixed-nav {
    padding-top: 60px;
}

article.widget-content {
    .clearfix;
}

#layout-wrapper {
    height: auto; 
    margin: 0 auto -117px;
    padding: 0 0 117px;
    background: @colorLayoutBackground;
    max-width: @maxWidth;
}

#layout-wrapper.fluid-layout {
    max-width: 100%;
}

.navbar-fixed-top {
    margin-left: auto;
    margin-right: auto;
}

.fluid-layout #layout-wrapper, .fluid-layout .navbar-fixed-top {
    max-width: 100%;
}

#layout-wrapper.sticky-footer {
 /*min-height: 100%; */
}

.navbar-nav {
  margin: 0;
  float:left;
}

/*change last value in margin and padding to adjust footer height*/
#footer {
    height: 117px;
    margin: 0 auto;
    background:@colorNavbar;
}

html{
    background:@colorNavbar;
}

.zone-footer {
    padding: 20px;
    padding-bottom:45px;
    width: 100%;
    text-align: center;
    color: @colorDarkText;

    a, a:hover, div {
        color: @colorDarkText;
    }
}

.modal {
    overflow: hidden;
    overflow-y: auto;
}

a {
    color: @colorLink;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        color: @ciderButtonsAndLinksLight;
    }
}

div, p, legend, input {
    color: @colorRegularParagraphText;
}

input:-webkit-autofill, 
textarea:-webkit-autofill {
    color: @colorRegularParagraphText !important;
}

.comments-1 & > h4, .comments-1 .media-heading {
    font-size: 14px;
    color: #888888;
}

.comment-form {
    .comments-1 {
        input {
            margin-bottom: 15px;
            padding: 4px 10px;
            margin-right: 10px;
            border-radius: 0;
            border: @boxBorders;
            box-shadow: @boxShadows;
        }

        textarea {
            margin-bottom: 15px;
            padding: 4px 10px;
            margin-right: 10px;
            border-radius: 0;
            border: @boxBorders;
            box-shadow: @boxShadows;
        }

        input.btn-primary {
            margin-bottom: 45px;
            padding: 4px 10px;
            margin-right: 10px;
            border-radius: 0;
            border: none;
            float: left;
        }
    }
}

.widget-aside-first {
    .form-control {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        color: @colorRegularParagraphText;
        vertical-align: middle;
        background-color: @colorLayoutBackground;
        background-image: none;
        border: @boxBorders;
        border-radius: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
        -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        -moz-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }
}

.aside-first-col {
    .make-md-column(3);

    .well {
        padding: 19px;
        margin-bottom: 0;
        border: none;
        border-radius: 0;
    }

    .well-sm {
        padding: 0;
    }
}

.aside-second-col {
    .make-md-column(3);

    .well {
        padding: 19px;
        margin-bottom: 0;
        border: none;
        border-radius: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
    }

    .well-sm {
        padding: 0;
    }
}

.widget-aside-second .navbar-form.navbar-right, 
.widget-aside-first .navbar-form.navbar-right {
    margin: 0;
    padding: 0;
    width: 100%;
}

.widget-aside-second .navbar-form .form-group, 
.widget-aside-first .navbar-form .form-group {
    width: 100%;
}

.widget-aside-second .form-control, 
.widget-aside-first .form-control {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: @colorRegularParagraphText;
    vertical-align: middle;
    background-color: @colorLayoutBackground;
    background-image: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -moz-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.well {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.aside-second-col {
    .well {
        border: @listBorders !important;
        box-shadow: @boxShadows !important;
    }
}

#Comments_SiteName {
    width: 29%;
}

.comments-3 {
    .media-content {
        margin-left: 10px;
        border-bottom: 3px solid #e3e3e3;
    }
}

.logreg {
    padding: 20px;
    border: none;
    margin-top: 50px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Roboto', sans-serif;
    line-height: 1.0;
    color: inherit;

    &.page-title {
        // This replicates the fieldset/legend styling
        line-height: 1.75em;
        margin-top: 0;
        margin-bottom: 20px;
        border-bottom: @horizontalSeparator;
    }
}

h1, h2, h3, h3 a {
    margin-top: 35px;
    margin-bottom: 10px;
    color: @colorDarkText;
}

h3 {
    a:hover {
        color: #888888;
    }
}

h1 {
    font-size: 30px;
}

h3, h4 {
    font-size: 20px;
    color: @colorRegularParagraphText;
}

* {
    font-family: 'Roboto', sans-serif;
}

.btn {
    a {
        color: @colorButtonPrimaryText;
    }
}

.full-width {
    width: 100%;
}

.half-width {
    // 49% to allow some padding between two buttons
    width: 49%;
}

.badge-of-honor{
    .make-lg-column(12);
}

.btn-primary, .primaryAction {
    color: @colorButtonPrimaryText;
    background-color: @ciderButtonsAndLinks;
    border: none;
    margin: 1px;
    font-size: 14px;
    outline: none;

    &[disabled] {
        background-color: @ciderDisabledButton;
    }
}

.btn-primary {
    a {
        color: @colorButtonPrimaryText;
    }
}

.csc-form {
    .make-row();

    .csc-label {
        .make-xs-column(8);
    }

    .csc-entry {
        .make-xs-column(4);
    }

    .radio {
        .btn.btn-link.btn-inline {
            padding: 0;
        }
    }
}

.payment-method-col{
    .make-sm-column(12);
}

.payment-review-row{
    .make-row();

    .payment-review-details-col{
        .make-xs-column(6);
    }

    .payment-review-amount-col{
        .make-xs-column(6);
        text-align: right;
    }
}

#estimated-payment-schedule .modal-dialog {
    width: 400px;

    .modal-body {
        padding-top: 0;
    }

    .initial-payment{
        margin-bottom: 10px;
    }

    .scheduled-payment, .initial-payment {
        .make-row();

        &.header {
            font-weight: bold;
        }

        .payment-date, .payment-amount, .description {
            .make-xs-column(4);
        }
    }
}

.register-modal-row, .order-placed-row{
    .make-row();
}

.register-modal-field{
    .make-sm-column(6);
    .make-sm-column-offset(3);
}

.btn-default {
    color: @colorButtonDefault;
    background: #A8A8A8;
    border: none;
    font-size: 14px;
    outline: none;
    margin:1px;
}

.btn, .btn:hover, .btn:active {
    color: @colorButtonPrimaryText;
}

.btn a, .btn:hover a {
    color: @colorButtonPrimaryText;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background-color: @ciderButtonsAndLinks;
    outline: none;
}

.btn-group {
    .btn-primary, .primaryAction {
        color: @colorRegularParagraphText;
        background-color: @colorLayoutBackground;
        border: @boxBorders;

        &:hover {
            background-color: @ciderToggleButtonLight;
        }

        &:active, &.active {
            color: @colorLayoutBackground;
            background-color: @ciderButtonsAndLinks;
            border-right: none;
            border-left: none;
        }
    }
}

.save-toggle {
    width: 100%;
}

.dual-radio-group {
    & > label {
        width: 49%;
    }
}

.btn-default:hover, .btn-default:active, .btn-default:focus {
    background: #A8A8A8;
    outline: none;
}

.input-group-btn {
    .btn-default {
        background: transparent;
        margin: 0 10px 0 10px;
        padding-top: 4px;
    }
}

.input-group-btn:last-child {
    & > .btn {
        margin-left: -1px;
    }
}

.widget-login-widget {
    padding: 15px 15px 10px 15px;
    min-height: 45px;

    .login-widget {
        span {
            &.user-welcome {
                padding-right: .4em;
            }

            &.user-logout {
                white-space: nowrap;
                display: inline-block;
            }
        }
    }
}

.page-header {
    padding-bottom: 0;
    margin: 20px 0 20px;
    border-bottom: none;

    @media (max-width: @screen-xs-max) {
        margin-top: 0;
    }
}

.pre-footer{
    background:@colorPreFooter;
    padding:20px;
    margin-top:20px;
}

.pre-footer-row{
        .make-row();
        margin-left:0;
        margin-right:0;
}

.pre-footer-col{
    .make-md-column(12);
}

.footer-quad-col{
    .make-md-column(2);
}

 .row-quad{
    .make-row();
    background:@colorNavbar;
    margin-top: 0;
    padding: 20px 10px;
    margin-left:0;
    margin-right:0;

    ul {
        list-style: none;
        -webkit-margin-before: 0;
        -webkit-margin-after: 0;
        -webkit-margin-start: 0;
        -webkit-margin-end: 0;
        -webkit-padding-start: 0;
        margin-bottom: 0;

        li {
            padding: 4px;
            padding-left: 0;
        }
    }
}

 .footer-row{
     .make-row();
    margin-left:0;
    margin-right:0;
 }

.row-tripel{
    .make-row();
    border: @listBorders;
    box-shadow: @boxShadows;
    color: #625C5C;
    margin-top: 10px;
    padding: 20px 10px;

    ul {
        list-style: none;
        -webkit-margin-before: 0;
        -webkit-margin-after: 0;
        -webkit-margin-start: 0;
        -webkit-margin-end: 0;
        -webkit-padding-start: 0;
        margin-bottom: 0;

        li {
            padding: 4px;
            padding-left: 0;
        }
    }
}

aside {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

a, a:focus {
    color: @ciderButtonsAndLinks;
}

.row-tripel h3, .row-quad h3, .well h3 {
    margin-top: 0;
}

.zone-content, .zone-messages, .zone-before-content {
    margin-bottom: 20px;
    .clearfix;
}

.content-items {
    list-style: none;

    * {
        .content-item {
            padding: 10px 15px 0 15px;
            overflow: hidden;

            header {
                color: #aaaaaa;
                padding-left: 15px;

                a {
                    color: #aaaaaa;
                }

                a:hover {
                    color: @colorDarkText;
                }
            }

            p {
                color: @colorDarkText;
                margin-bottom: 15px;
            }

            .product-details {
                margin:15px;
                p {
                    margin-left: 0;
                    max-width: 100%;
                }
            }
        }
    }
}

.content-item {
    p {
        margin: 10px 10px 10px 0;
    }

    .product-image {
        padding-bottom: 10px;
    }
}

.list-box(@padding: 10px) {
    overflow: hidden;
    margin-bottom: 30px;
    padding: @padding;
    border: @listBorders;
    box-shadow: @boxShadows;
}

.new-payment-method-row{
    .make-row();
}

.new-payment-method-col{
    .make-sm-column(6);
}

fieldset.saved-payment-methods {
    margin-top: 50px;
}

fieldset.new-payment-method {
    clear:both;

    .new-payment-fields {
        .make-row();

        .btn-group {
            margin: 5px;
        }

        .payment-type-selector, .new-payment-form {
            .make-sm-column(6);
            .make-sm-column-offset(3);
        }
    }
}

.payment-list {
    .list-box;

    .column-headers {
        font-weight: bold;
    }

    .amount-received {
        padding-top: 10px;
    }
}

.summary-info {
    font-weight: bold;
}

.product-list {
    .list-box;

    img {
        max-width:100%;
    }

    .product-details {
        h3 {
            margin-top: 0;
        }
    }

    .participants {
        h3 {
            margin-top: 2px;
        }
    }
}

.shopping-cart-line-ship-to-address {
    .make-row();

    .multiple-ship-quantity {
        .make-md-column(2);
    }

    .multiple-ship-address-col {
        .make-md-column(8);
    }

    .multiple-ship-actions {
        .make-md-column(2);

        @media(min-width: @screen-md-min) {
            margin-top: 10px;
        }
    }
}

.multiple-ship-details-col {
    .make-md-column(4);
}

.product-added-row {
    .make-row();
}

.product-added-col {
    .make-sm-column(12);
}

.cart-product-list {
    .make-md-column(12);
    .product-list;
    margin-bottom: 10px;

    .cart-product-image {
        float: left;
        max-width: 160px;
        padding: 15px;
    }

    .cart-product-detail {
        .make-xs-column(10);
        float: left;
        padding: 15px;
        padding-left: 0;

        h3 {
            margin-top: 0;
        }

        .cart-line-row {
            .make-row();

            .cart-line-details {
                .make-md-column(5);
            }

            .cart-line-price {
                .make-md-column(3);

                .donation-frequency {
                    margin-top: 5px;
                }
            }

            .quantity-picker {
                .make-md-column(2);

                input {
                    display: inline;

                    &.quantity {
                        padding-left: 7px;
                        padding-right: 7px;
                        min-width: 65px;
                    }
                }

                select.select-quantity {
                    padding-left: 3px;
                    padding-right: 7px;
                    min-width: 45px;
                }
            }

            .cart-line-subtotal {
                .make-md-column(2);
            }
        }

        a.update-quantity, a.edit-amount, a.update-amount {
            cursor: pointer;
        }

        article.content-item {
            margin: 0;
        }

        p.text-field {
            margin: 0;
        }

        & > .product-top-row {
            & > div {
                line-height: 34px;
            }
        }

        .text-field {
            color: rgb(177, 177, 177);
        }
    }

    .cart-item-delete {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        z-index: 10;

        .remove-item {
            display: block;
            color: @colorButtonPrimaryText;
            background-color: @ciderButtonsAndLinks;
            border: none;
            font-size: 14px;
            outline: none;
            margin: 0;
            padding: 7px 10px 9px 22px;
            border-radius: 0 0 0 16px;
            
            &:before {
                font-family: 'FontAwesome';
                content: '\f00d';
                color: @colorButtonPrimaryText;
                margin: 10px 10px 0 -10px;
            }
        }
    }
}

.product-summary {
    width: 100%;
}

.item-ship-to-address {
    .make-sm-column(4);
}

.order-review-row {
    .make-row();

    .review-shipping-address-col, .review-billing-address-col {
        .make-md-column(4);
    }

    .review-order-summary-col {
        .make-md-column(4);
        margin-bottom: 20px;

        &.review-order-summary-offset {
            .make-md-column-offset(4);
        }

        .review-summary-row {
            .make-row();

            .review-summary-line-label {
                .make-xs-column(5);
            }

            .review-summary-line-action {
                .make-xs-column(3);
            }

            .review-summary-line-amount {
                .make-xs-column(4);
            }
        }

        .change-promotion {
            .make-row();
            margin-top: 5px;
            margin-bottom: 5px;

            .promotion-field {
                .make-sm-column(8);
            }

            .apply-change {
                .make-sm-column(4);
            }
        }

        .choose-existing-gift-certificate {
            font-size: 12px;
            float: right;
        }
    }

    .review-order-items {
        .make-md-column(8);
        .make-md-column-pull(4);
    }

    .review-payment {
        .make-md-column(4);
        .make-md-column-push(8);

        form.order-payment-form {
            .list-box;
        }

        .selected-payment-method-description {
            margin-left: 7px;
        }

        .installment-billing-options {
            border-bottom: @horizontalSeparator;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }

        .place-order {
            margin-top: 15px;
        }

        .terms-and-conditions-group {
            margin-top: 10px;
            font-size: 0.9em;
        }
    }

    .external-payment-review {
        .make-md-column(4);
    }
}

.payment-method-selection {
    .new-credit-card, .new-bank-account, .new-hosted-payment, .select-payment-method, .bill-me-message {
        margin-bottom: 10px;
        border-bottom: @horizontalSeparator;
        padding: 0 20px 10px 20px;

        label.save-payment-method {
            font-weight: normal;
            margin-right: 20px;
        }

        label.make-default-payment-method {
            font-weight: normal;
        }

        div.expired-card-warning {
            color: @alert-danger-text;
            font-size: small;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .consent-date-field input {
            padding-left: 12px;
        }
    }
}

.review-page {
    .order-line-details {
        .make-row();

        .order-line-summary {
            .make-sm-column(8);

            &:only-child {
                .make-sm-column(12);
            }

            .product-summary {
                float: none;
                clear: both;
            }

            .order-line-actions {
                .review-order-line-participants {
                    float: left;
                }

                .review-order-line-eula {
                    float: right;
                }
            }
        }

        .order-line-shipping-address {
            .make-sm-column(4);
        }
    }

    .change-order {
        padding: 0 5px;
        margin-bottom: 2px;
    }
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    background-color: @ciderButtonsAndLinks;
}

.payment-method-heading {
    .make-row();

    .cc-payment-code, .dd-payment-code {
        padding-left: 5px;
    }

    .is-web-default {
        padding-left: 5px;
    }

    .card-type {
        text-align: left;

        .card-provider {
            .make-xs-column(6);
            width: 60%;
            font-size: 18px;
        }

        .web-default-status {
            .make-xs-column(6);
            width: 40%;
            font-size: 16px;
            text-align: right;
            float: right;

            button {
                font-size: 14px;
            }
        }
    }

    .card-number {
        .make-row();
    }
}

button.btn.change-order, button.btn.change-shipping-method, button.btn.change-payment,
a.btn-link.change-order, a.btn-link.add-credit-card, a.btn-link.add-bank-account,
a.change-gift-certificate {
    font-size: 12px;
}

@thirty: 30;
.product-details {
    width: calc(~"100%" - unit(@thirty, px));
    margin-bottom: 5px;

    .add-to-cart {
        float: right;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 7px;
        text-transform: capitalize;
        line-height: 1.2;
        padding-top: 7px;
    }
}

ul.included-selectable-package-components {
    display: inline;
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        display: inline-block;

        &:not(:last-child):after {
            content: ",";
        }
    }
}

.product-image {
    float: left;
    padding-right: 17px;

    &.image-frame {
        border: none;
        box-shadow: none;
    }
}

.product-category-term {
    ul {
        -webkit-margin-before: 0;
        -webkit-margin-after: 0;
        -webkit-margin-start: 0;
        -webkit-margin-end: 0;
        -webkit-padding-start: 0;
    }

    page-header {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    h1 {
        margin-top: 10px;
    }
}

.product-price, .cart-product-price {
    padding: 0;
    color: @ciderButtonsAndLinks;
}


.member-discount-price {
    .member-discount-text {
        font-size: 16px;
    }
    .member-price {
        padding: 0;
        color: @ciderButtonsAndLinks;
        font-size: 20px;
        font-weight: bold;
        text-align: right;
    }
}

.add-to-cart.mini-add-to-cart {
    color: @ciderButtonsAndLinks;
}

.mini-add-to-cart-col {
    .make-xs-column(12);
}

.product-list-price-equal {
    color: @colorButtonDefault;
}

.product-list-price-strike-through {
    color: @colorButtonDefault;
    text-decoration: line-through;
}

.discount-text {
    .make-xs-column(6);
    font-weight: normal;
    color: @colorDarkText;
    font-size: 16px;
    padding: 0;
    text-align: left;
}

.product-list-text {
    .make-xs-column(6);
    font-weight: normal;
    font-size: 14px;
    padding: 0;
    text-align: left;
}

.product-price {
    text-align: right;
}

.promotion-price {
    padding: 0;
    text-align: right;

    .item-savings-col {
        .make-xs-column(12);
    }
}

.product-list-text-col, .product-list-price-col,
.discount-text-col {
    .make-xs-column(6);
}

.product-narrative {
    font-size: .85em;

    &:not(:empty) {
        margin: 5px 0;
    }
}

.content-items, .content-item {
    .product-narrative {
        p {
            margin: 0;
        }
    }
}

.add-to-cart-container {
    .add-to-cart-promote-discount {
        color: @ciderButtonsAndLinks;

        &:not(:empty) {
            margin-top: 5px;
        }
    }
}


.add-to-cart-promoted-membership-container {
    padding-top: 10px;
    margin-top: 10px;
    border-top: solid 1px @colorBorder;

    .add-to-cart-promote-discount {
        color: @ciderButtonsAndLinks;
    }
}


    .product-add-to-cart {
        .make-sm-column(3);
        border: @listBorders;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: -30px;
        box-shadow: @boxShadows;
    }

    .product-item-number {
        color: @ciderButtonsAndLinks;
        padding-top: 15px;
    }

    .zone-content {
        .content-item .last {
            margin-bottom: 30px;
        }
    }

    .label-field, .label-field .prompt, .label-field .field {
        float: left;
    }

    .dl-horizontal {
        dt {
            color: #888888;
            width: 100px;
        }

        dd {
            color: #888888;
            margin-left: 120px;
        }
    }

    .form-group {
        .clearfix;
        margin-top: 5px;
        margin-bottom: 5px;

        .text-only {
            padding-top: 7px;
        }

        label.privacy-settings-checkbox {
            margin-left: 12px;
        }
    }

    .continue-to-hosted-order {
        margin-top: 10px;
    }

    .form-control.secondary {
        margin-top: -4px;
    }

    .form-control.guest-receipt-registration {
        margin-top: 5px;
    }

    .form-horizontal {
        .radio-label {
            margin-top: -8px;
        }

        .form-group, .horizontal-form-group {
            .make-row();
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .widget-cart-widget {
        float: right;
    }

    .cart-button {
        position: relative;
        color: #000;

        i {
            color: @ciderButtonsAndLinks;
        }
    }

    .cart-count {
        position: relative;
        color: @colorCartCount;
        text-shadow: 1.3px 1.3px 0 @colorNavbar, -1.3px -1.3px 0 @colorNavbar, 1.3px -1.3px 0 @colorNavbar, -1.3px 1.3px 0 @colorNavbar, 0 1.3px 0 @colorNavbar, 1.3px 0 0 @colorNavbar, 0 -1.3px 0 @colorNavbar, -1.3px 0 0 @colorNavbar;
    }

    .cart-money {
        color: #000;
        font-size: 12px;
    }

    .shopping-cart-page {
        margin-right: 15px;

        .page-title {
            font-size: 1.75em;
            padding-bottom: 0;
        }

        .cart-contents-row {
            .make-row();

            .cart-contents-col {
                .make-md-column(6);
            }
        }

        #empty-cart-message {
            font-size: 1.5em;
            text-align: center;

            &:before {
                content: "\f07a";
                display: block;
                font-family: FontAwesome;
                font-size: 2em;
                color: #888888;
                line-height: 1;
            }

            a {
                font-size: .75em;
                display: block;
            }
        }
    }

    .cart-coupon-promotion-row {
        .make-row();

        .cart-promotion-col, .cart-coupon-col, .cart-currency-col {
            .make-md-column(4);
        }
    }

    .cart-checkout-options {

        .guest-checkout-row, .mini-log-in-row,
        .continue-shopping-row {
            .make-row();
        }

        .authenticated-checkout-options {
            .make-md-column(4);
            .make-md-column-offset(2);
        }

        .guest-checkout-options {
            .make-md-column(6);
            padding-right: 16px;

            .checkout-option {
                .make-md-column(9);
                .make-md-column-offset(3);
            }

            .mini-log-on {
                .make-md-column(9);
                .make-md-column-offset(3);

                .log-on-textbox {
                    .make-sm-column(12);
                }

                .open-auth-options {
                    border-bottom: none;
                    margin-bottom: auto;

                    div.open-auth-separator {
                        display: none;
                    }
                }

                .single-sign-on {
                    margin: 0;
                }
            }
        }
    }

    .account-login-authentication {
        .make-row();

        .log-on-form {
            .make-md-column-offset(0);

            .social {
                .make-lg-column-offset(0);
            }
        }
    }

    .log-on-form {
        .make-xs-column(12);
        .make-md-column(6);
        .make-md-column-offset(3);

        .logon-row {
            .make-row();
        }

        .social {
            .make-lg-column-offset(1);
        }

        .log-on-label {
            .make-lg-column(12);
            text-align: left;
        }

        .log-on-textbox {
            .make-lg-column(12);
        }

        .remember-me {
            .make-sm-column(12);
            margin-left: 0;
        }

        .log-on-buttons {
            .make-sm-column(12);
        }

        .sso-login-button {
            .make-sm-column(12);
        }

        .form-group.sso-button {
            .make-sm-column(7);
            margin-top: 17px;
            padding: 0;
        }

        .sso-separator-col {
            .make-sm-column(4);
            .make-sm-column-offset(1);
            border-bottom: 1px solid lightgrey;
            margin-right: 0;
        }

        div.sso-separator {
            margin-top: 14px;
            margin-bottom: -20px;
            border-radius: 100px;
            background-color: @ciderButtonsAndLinks;
            width: 40px;
            height: 40px;
            padding-top: 7px;
            color: #FFFFFF;
            font-size: 125%;
            text-align: center;
            margin-left: 36%;
        }

        .form-group {
            .form-horizontal.form-group;
            .clearfix;
        }

        .logon-alternate-title {
            text-align: center;
        }

        &.login-account-number {
            .make-md-column-offset(0);
        }
    }

    .embedded-login-form {
        .log-on-form {
            .make-md-column(5);
            .make-md-column-offset(1);
        }
    }

    .guest-register-row, .guest-row, .guest-checkout-row {
        .make-row();
    }

    .no-account-col {
        .make-md-column(6);
    }

    .sso-no-account-col {
        .make-md-column(6);
        padding-left: 32px;
    }

    .register-col, .continue-guest-col {
        .make-sm-column(6);
        .make-sm-column-offset(3);
    }

    .sso-register-col, .sso-continue-guest-col {
        .make-sm-column(7);
        padding-right: 39px;
    }

    #guest-checkout-form {
        .shipping-address-toggle-col, .guest-checkout-continue, .g-recaptcha {
            .make-md-column(4);
            .make-sm-column-offset(3);
        }
    }

    .activate-account-button {
        .make-md-column(4);
        .make-md-column-offset(3);
    }

    .order-history-search {
        .make-row();

        .search-field {
            .make-md-column(6);
        }

        .date-range-selection {
            .make-md-column(3);
        }

        .search-action {
            .make-md-column(3);

            button {
                .full-width;
            }
        }

        .result-summary-text {
            .make-md-column(12);
            margin-top: 10px;
            margin-bottom: -15px;
        }
    }

    .membership-directory {
        .membership-directory-search {
            .order-history-search;

            .structure-node-selection {
                .make-md-column(3);

                #change-search-structure-node {
                    text-align: left;
                    cursor: pointer;
                    -webkit-appearance: menulist;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }
            }
        }

        .membership-directory-results {
            margin-top: 20px;
            padding-top: 20px;
            border-top: @horizontalSeparator;

            .results {
                .make-row();

                .member-mini-profile {
                    .make-sm-column(4);

                    .profile-content {
                        .list-box;
                        margin-bottom: 15px;

                        .profile-image {
                            float: left;
                            padding-right: 10px;
                        }

                        .profile-info {
                            h4.name {
                                font-size: 1.2em;
                                margin-top: 5px;
                                margin-bottom: 5px;
                            }

                            .email {
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
        }
    }

    ol.order-search-results {
        border-top: @horizontalSeparator;
        margin-top: 20px;
        padding: 0;
        list-style: none;

        li {
            border-bottom: @horizontalSeparator;
            margin-top: 20px;

            .order-details, .order-line-display {
                padding-bottom: 20px;

                p {
                    padding: 0;
                    margin: 0;
                }

                h4 {
                    font-size: 18px;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    .order-detail-billing {
        .make-sm-column(6);
    }

    .order-summary-line {
        .make-row();

        .summary-line-label {
            .make-xs-column(8);
        }

        .summary-line-amount {
            .make-xs-column(4);
        }
    }

    .control-label {
        h4 {
            margin-bottom: 0;
        }
    }

    .cart-total {
        margin-top: 0;
    }

    .cart-total-savings {
        margin-top: 5px;
    }

    .review-change-donation {
        input, select {
            width: 200px;
        }

        button.btn.btn-link {
            padding: 0;
        }

        .donation-frequency {
            margin-top: 5px;
        }
    }

    .cart-product-image-col {
        .make-xs-column(2);
    }

    .order-line-quantity {
        width: 3em;
        padding-top: 0;
        padding-bottom: 0;
        height: initial;
    }

    form {
        .form-inline {
            float: left;
        }

        select {
            &.renewal-choices {
                width: 40%;
            }
        }
    }

    .btn-primary.update-cart {
        padding: 7px;
    }

    .content-item div.product-summary ~ p {
        margin: 0;
        padding-left: 15px;
        clear: both;
        font-size: 12px;
        color: #aaaaaa;
    }

    .catalog-product-summary {
        .make-row();
    }

    .product-advertisement-widget-content {
        .make-sm-column(6);
        .pull-right();
    }

    .product-advertisement-widget-details {
        .make-sm-column(3);
    }

    .product-advertisement-widget-img {
        .make-sm-column(6);
    }

    .vcard {
        border: @listBorders;
        box-shadow: @thinBoxShadows;
        border-radius: 15px;

        .card-holder {
            max-width: 250px;
        }

        &.hide-border {
            i, h3 {
                color: @ciderButtonsAndLinks;
            }
        }

        .gift-msg {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .btn:not(.btn-link) {
            .full-width;
            display: block;
        }

        .edit-address {
            &.select-card-activated {
                background: rgb(199, 199, 199);
                margin-bottom: 0;
            }
        }

        .address-card-controls {
            .make-row();
            margin: 10px 0 0 0;

            #edit-ship-to {
                .make-xs-column(5);
                margin-right: 10px;
            }

            #remove-ship-to {
                .make-xs-column(6);
            }
        }
    }


    #shipping-information {
        .clearfix;

        .new-ship-to-row {
            .make-row();

            .new-ship-to-col {
                .make-sm-column-offset(2);
                display: flex;
                flex-wrap: wrap;

                .vcard {
                    min-width: 200px;
                }
            }
        }

        .add-address-link {
            .make-sm-column-offset(2);
        }

        .ship-to-multiple-btn {
            padding: 0;
        }
    }

    btn.btn-inline.add-gift-message-col {
        padding-left: 0;
    }

    .add-shipping-address-col {
        .make-sm-column-offset(2);
    }

    #add-ship-to-modal {
        .horizontal-form-group {
            margin-bottom: 5px;
        }
    }

    #ship-to-multiple {
        padding-left: 0;
    }

    .promotion-item-row, .radio-button-row {
        .make-row();
    }



    .include-billing-row {
        .make-row();
    }

    .include-billing-col {
        .make-sm-column-offset(2);

        include-billing-box {
            margin-left: 5px;
        }
    }

    .place-order-col {
        .make-md-column(4);
        .make-sm-column-offset(2);
    }

    .promotion-order-form {

        .shipping-address-toggle-row .shipping-address-toggle-col {
            .make-sm-column-offset(3);
            .make-sm-column(8);
        }
    }

    .payment-method-options {
        .make-row();

        .payment-method-selection {
            .make-sm-column(4);
            .make-sm-column-offset(3);
        }
    }

    .multiple-ship-col {
        .make-sm-column(3);
    }

    .multiple-ship-offset {
        .make-sm-column-offset(9);
    }

    .shipping-address-toggle-row {
        .make-row();

        .shipping-address-toggle-col {
            .make-sm-column-offset(2);
            .make-sm-column(8);
        }
    }

    .notify-me.address-form .horizontal-form-group .horizontal-form-control {
        .make-sm-column(6);
    }

    .customer-edit-address {
        .address-form .horizontal-form-group .horizontal-form-control, .save-edit-address, .save-add-address {
            .make-sm-column(5);
        }

        .horizontal-form-group, .form-group {
            .make-row();

            .save-add-address {
                .make-sm-column-offset(3);
            }

            .cancel-edit-address {
                .make-sm-column(2);
                .make-sm-column-offset(1);
            }
        }
    }

    .save-new-address-col {
        .make-sm-column(4);
        .make-md-column-offset(2);
    }

    .title-box {
        .make-row();
        background: rgb(235, 235, 235);
        background: @ciderButtonsAndLinksLight;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 10px;

        h4 {
            color: @colorButtonPrimaryText;
            font-size: 18px;
        }

        .order-placed-date-col, .order-number-col,
        .renewal-title {
            .make-sm-column(6);
        }
    }

    .guest-button {
        width: 100%;
        min-width: 200px;
    }

    .form-group.card-number {
        font-size: 18px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .remove-address, .remove-address:hover, .remove-address:focus, .remove-address:active {
        background-color: @alertRed;
    }

    .remove-address-col {
        .make-sm-column(12);
    }

    ul.checkout-chain {
        background: @ciderButtonsAndLinks;

        li {
            &.checkout-chain-12 {
                .make-sm-column(12);
            }

            &.checkout-chain-6 {
                .make-sm-column(6);
            }

            &.checkout-chain-4 {
                .make-sm-column(4);
            }

            &.checkout-chain-3 {
                .make-sm-column(3);
            }

            &.checkout-chain-2-4 {
                .make-sm-column(2.4);
            }

            &.checkout-chain-2 {
                .make-sm-column(2);
            }

            div {
                color: @colorButtonPrimaryText;
            }
        }

        li.active + li {
            background: @ciderButtonsAndLinksLight;
        }

        li.active.first {
            &:hover {
                background: @ciderButtonsAndLinksLight;
            }

            background: @ciderButtonsAndLinksLight;
        }
    }

    .checkout-chain div:hover, .checkout-chain li div:hover, .checkout-chain li div:active, .checkout-chain li div:focus {
        background: transparent;
    }

    .checkout-chain li, .checkout-chain li:hover {
        border-bottom: 30px solid @ciderButtonsAndLinks;
        border-left: 20px solid transparent;
        height: 0;
        border-top: 30px solid @ciderButtonsAndLinks;
    }

    .checkout-chain li.active, .checkout-chain li.active:hover {
        border-bottom: 30px solid @ciderButtonsAndLinksLight;
        border-top: 30px solid @ciderButtonsAndLinksLight;
        background: @ciderButtonsAndLinks;
    }

    .title-action {
        .make-row();

        .title {
            .make-sm-column(8);
            margin-top: 12px;
        }

        .action {
            .make-sm-column(4);
            text-align: right;
            font-size: 14px;
        }

        .standalone-action {
            .action;
            .make-sm-column-offset(8);
            margin-bottom: -25px;
        }
    }

    .left-align {
        margin-left: 0 !important;
        padding-left: 0 !important;
    }

    img + form {
        margin-top: 8px;
    }

    .credit-card-detail {
        margin-top: 5px;
    }

    .edit-link {
        margin-left: 10px;
    }

    .credit-card.ccard {
        .vcard;
        padding-bottom: 5px;

        .expire-date {
            font-size: 1.1em;

            &.date-is-expired {
                color: @alertRed;
            }
        }

        .select-card {
            width: 100%;
        }

        .form-group {
            margin-left: 0px;
            margin-right: 0px;
        }

        &.add-new-payment-method-link-form {
            border: dashed 2px #bfbfbf;
            box-shadow: @boxShadows;
            text-align: center;
            background-color: @colorShadedNote;
            margin-right: 0;

            .center-link {
                margin-top: 13%;
            }

            .add-new-payment-method-link {
                font-size: 2.3em;
                line-height: 90px;
                color: @ciderButtonsAndLinks
            }

            .add-credit-card-button, .add-bank-account-button {
                .make-row();
            }
        }

        .default-payment-method-banner {
            background-color: @colorNoteHeader;
            color: @colorButtonPrimaryText;
            border-top-left-radius: 1em;
            border-top-right-radius: 1em;
            margin-top: -51px;
            text-align: center;
            line-height: 2.2em;
            float: left;
            padding: 0 15px;
        }
    }

    #new-payment-modal {
        .modal-dialog {
            margin-top: 60px;
        }
    }

    .auto-renew-dialog {
        margin-top: 100px;
    }

    .add-new-payment-method-button {
        margin-top: 20px;
    }

    .add-new-payment-method-modal-body {
        padding-top: 0px;
    }

    .add-payment-modal.modal-footer {
        border-top: 0px;
    }

    .edit-slider-col, .remove-slider-col {
        .make-xs-column(6);

        &.remove-direct-debit {
            .make-sm-column-offset(6);
        }
    }

    .credit-card-expiration-row {
        .make-row();

        .cc-expiration-col {
            .make-xs-column(4)
        }

        .cc-expiration-month-col {
            .make-xs-column(3);
        }

        .cc-expiration-year-col {
            .make-xs-column(5);
        }
    }

    .cc-confirmation-col, .cc-remove-col {
        .make-xs-column(6);
    }

    .pay-later-col {
        .make-sm-column(6);
    }

    .btn-link {
        color: @ciderButtonsAndLinks;
        outline: none;

        i {
            float: left;
            padding-right: 4px;
            margin-top: -3px;
        }

        &.btn-inline {
            padding: 0 5px;
            font-size: 85%;
            margin-bottom: 2px;

            &.add-participant {
                padding-left: 0;
            }
        }
    }

    .btn-link:hover, .btn-link:active, .btn-link:focus {
        color: @ciderButtonsAndLinks;
        text-decoration: none;
        outline: none;
    }

    .add-address {
        padding-bottom: 20px;
    }

    .cart-stock-status {
        margin-left: 100px;
        width: 100%;
    }

    .fa.in-stock {
        color: @ciderButtonsAndLinks;
    }

    .fa.delayed {
        color: #eaac2d;
    }

    .fa.out-of-stock {
        color: @ciderButtonsAndLinks;
    }

    .inventory-status {
        padding: 0;
    }

    .add-to-cart-col {
        .make-sm-column(3);
    }

    .add-to-cart-btn-col {
        .make-sm-column(12);
    }

    .add-to-cart {
        float: right;
        margin-bottom: 0;
        border: @listBorders;
        box-shadow: @thinBoxShadows;
        padding: 15px;
        margin-top: 10px;
        margin-left: 5px;

        &.btn {
            padding: 7px;
            width: 100%;
            margin: 0 0 5px 0;
        }
    }

    .order-detail-item {
        padding-top: 20px;
    }

    .order-detail-shipping-address {
        padding-top: 20px;
    }

    .order-detail-payment-breakdown {
        padding-top: 40px;
    }

    .recipients-col {
        .make-md-column(3);
    }

    .ordered-item-details-col {
        .make-md-column(9);
    }

    .order-summary-col {
        .make-sm-column(4);
    }

    .order-summary-offset {
        .make-sm-column-offset(2);
    }

    .activate-participant {
        .make-md-column(4);
        .make-md-column-offset(3);
    }

    .subscription-receipt-row, .participant-row,
    .update-participants-row, .subscription-title-row {
        .make-row();
    }

    .manage-participants-col {
        .make-sm-column(8);
    }

    .update-participants-col {
        .make-sm-column(4);
    }

    .subscription-title-col {
        .make-md-column(12);
    }

    .order-product-details {
        h3 {
            margin-top: 0;
            padding-top: 0;
        }
    }

    .order-line-display {
        padding-bottom: 20px;
    }

    #promotionChoice {
        height: 31px;
    }

    .last-issue-sent {
        color: @ciderButtonsAndLinks;
        font-weight: 700;
    }

    #SuspendReason {
        width: 155px;
    }

    .list-unstyled {
        li {
            padding-bottom: 20px;
        }
    }

    .radio-group {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .navbar-fixed-top, .navbar-fixed-bottom {
        width: 100%;
    }

    .nav-row {
        .make-row();
    }

    .nav-col {
        .make-md-column(12);
    }

    .featured-row {
        .make-row();
    }

    .before-main-row {
        .make-row();
    }

    .before-main-col {
        .make-md-column(12);
    }

    .media-library-picker-field-product-banner-image {
        img {
            .img-responsive;
            width: 100%;
            margin-bottom: 25px;
        }
    }

    .product-row {
        .make-row();

        .product-description {
            width: 46%;

            &.product-description-col-wide {
                .make-sm-column(9);
            }

            &.product-description-col-narrow {
                .make-sm-column(6);
            }

            h1 {
                margin-top: 0;
            }
        }
    }

    .glyphicon-search {
        color: @colorButtonDefault;
    }

    .image-frame {
        box-shadow: @boxShadows;
        border: @listBorders;
        padding: 15px;
    }

    .content-row.prime {
        .make-row();
        padding-top: 30px;
        
        @media (max-width: @screen-xs-max) {
            padding-top: 0;
        }

        padding-bottom: 10px;
    }

    .container {
        padding-right: 45px;
        padding-left: 45px;
        max-width: @maxWidth;
    }


    hr.total-line {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .order-total {
        font-size: 18px;
        font-weight: bold;
        color: @alertRed;

        &.down-payment {
            color: @colorDarkText;
        }
    }

    .account-summary-row {
        .make-row();
        margin-bottom: 20px;
    }

    .account-summary-line {
        .make-row();
    }

    .account-summary-col {
        .make-sm-column(4);
        .make-sm-column-offset(8);
    }

    .account-summary-label, .account-summary-amount {
        .make-xs-column(6);
    }

    .account-total-label {
        .make-xs-column(7);
    }

    .account-total-amount {
        .make-xs-column(5);
    }

    .note-row {
        .make-row();
        margin-left: 0;
        margin-right: 0;

        &.header {
            font-weight: bold;
        }

        &.shaded {
            background: @colorShadedNote;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .note-info-row {
        .make-row();
        margin-left: 0;
        margin-right: 0;

        .col-sm-12 {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        &.shaded {
            background: @colorShadedNote;
        }

        .note-info-label {
            font-weight: bold;
        }

        .note-info-col {
            .make-sm-column(8);
        }
    }

    .note-header-row {
        .make-row();
        background-color: @colorNoteHeader;
        font-weight: bold;
        margin-left: 0;
        margin-right: 0;

        .contact-number-col, .contact-date-col,
        .note-status-col, .note-subject-col {
            color: @colorDarkText;
        }
    }

    .contact-number-col, .contact-date-col, .note-status-col {
        .make-sm-column(2);
    }

    .note-subject-col {
        .make-sm-column(5);
    }

    .field-validation-valid {
        display: none;
    }

    .validation-summary-valid {
        display: none;
    }

    .breadcrumb {
        margin-left: 15px;
        margin-right: 15px;
    }

    .img-responsive {
        &.img-center {
            margin: 0 auto;
        }
    }

    .product-image-carousel {
        .carousel-indicators {
            &.product-image-carousel-indicators {
                li.active img {
                    border-color: @ciderButtonsAndLinks;
                }
            }
        }
    }

    .carousel-image-col {
        .make-sm-column(3);
    }

    .image-frame-col {
        .make-sm-column(3);
    }

    .carousel {
        margin-bottom: 20px;

        .carousel-indicators {
            bottom: 0;
            opacity: .7;

            .active {
                background-color: @ciderButtonsAndLinks;
            }

            li {
                border: 1px solid @ciderButtonsAndLinks;
            }
        }

        .carousel-control {
            width: 7%;
            color: @ciderButtonsAndLinks !important;
            opacity: 1;
            text-shadow: none;
            font-size: @bodyFontSize;

            &:hover {
                opacity: .6;
                color: @ciderButtonsAndLinks;
                background: transparent !important;
            }

            &.left, &.right {
                background: transparent;
            }

            .icon-prev, .icon-next {
                font-family: FontAwesome;
                font-size: 1.33333333em;
                width: unset;
                height: unset;
            }

            .icon-prev:before {
                content: "\f053";
            }

            .icon-next:before {
                content: "\f054";
            }
        }
    }

    .widget-aside-second {
        .carousel-control {
            width: 15%;
        }
    }

    .product-list-widget-title {
        h3 {
            color: @colorBodyText;
        }
    }

    .preserve-newlines {
        white-space: pre-line;
    }

    .questionnaire-question {
        .make-row();
        text-align: match-parent;

        .question-label {
            .make-md-column(4);
            text-align: right;

            label {
                padding-top: .5em;
            }
        }

        .question-response {
            .make-md-column(4);
        }
    }

    .place-order {
        margin-top: 20px;
    }

    .lregister {
        border-top: @horizontalSeparator;
        margin-top: 20px;
        padding-top: 20px;
        text-align: center;
    }

    #toTop {
        display: none !important;
    }

    #pagination {
        text-align: center;
        padding-top: 10px;
    }

    .modal-footer {
        padding: 9px 10px 10px;
        clear: both;

        .btn {
            margin-bottom: 0;
        }
    }

    .participants-editor {
        clear: both;
        margin-bottom: 20px;

    .participant-email, .participant-name {
        vertical-align: middle;
        margin: 3px 0px 3px 0px;
    }


    .delete-participant, .edit-participant, .add-participant {
        font-size: 85%;
        margin-left: 5px;
        cursor: pointer;
        display:inline-block;
    }
}

    .edit-addresses-row {
        clear: both;
        margin-bottom: 20px;

        .shipping-address {
            .make-sm-column(9);
            vertical-align: middle;
            padding: 0;
            margin-bottom: 5px;
        }

        .shipping-address-controls {
            .make-sm-column(3);
            padding-right: 0;
            margin-top: 5px;
        }

        .remove-shipping-address, .edit-shipping-address {
            font-size: 85%;
            margin-right: 15px;
            cursor: pointer;
        }
    }

    #promotion-shipping-addresses-modal {
        .form-group {
            margin-bottom: 5px;
        }
    }

    .add-address-btn {
        clear: left;
        float: left;

        .fa-plus {
            margin-top: 3px;
        }
    }

    .choice-image {
        img {
            max-width: 255px;
        }
    }

    .selectable-item-selected(@highlightColor) {
        border-color: @highlightColor;
        box-shadow: 0 0 35px 0 @highlightColor;

        .overlay, .overlay-corner {
            display: block;
        }

        opacity: 1.0;
    }

    .selectable-item(@highlightColor) {
        cursor: pointer;
        -webkit-transition: all 0.15s linear;
        -moz-transition: all 0.15s linear;
        -o-transition: all 0.15s linear;
        transition: all 0.15s linear;
        opacity: 0.8;

        &:hover, &.selected {
            .selectable-item-selected(@highlightColor);
        }
    }

    .promotion-product {
        .selectable-item(@colorHighlight);
        float: left;
        margin: 10px;
        overflow: hidden;
        border: solid 2px @colorLayoutBackground;

        &.product-list {
            margin: 10px;

            .promotion-product-checkbox {
                float: right;

                .fa-square-o {
                    margin-right: 3.7px
                }
            }
        }

        img {
            -webkit-transition: opacity 0.30s linear;
            -moz-transition: opacity 0.30s linear;
            -o-transition: opacity 0.30s linear;
            transition: opacity 0.30s linear;
        }

        .overlay {
            display: none;
            width: 60px;
            height: 60px;
            position: absolute;
            z-index: 5001;

            i {
                color: white;
                padding: 10px;
            }
        }

        .overlay-corner {
            display: none;
            width: 60px;
            height: 60px;
            position: absolute;
            z-index: 5000;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                border-top: 60px solid rgba(132, 223, 110, 1);
                border-right: 60px solid transparent;
                width: 0;
            }
        }

        button:not(.selectable-unit-package-btn) {
            display: none;
        }
    }

    ol.promotion-take-all {
        // Hide highlighting from take all promotion landing page element
        .promotion-product {
            &, &:hover, &.selected {
                .list-box;
                border: solid 2px @colorLayoutBackground;
                cursor: default;
                opacity: 1;
            }
        }
    }

    .promotion-bundle-order-total {
        margin-top: 0;
        margin-bottom: 25px;
    }

    .back-arrow {
        color: @ciderButtonsAndLinks;
    }

    .classic-quickorder-choices {
        .make-xs-column(1);

        input {
            margin-top: 66px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .promotion-narrative {
        text-align: center;
        max-width: 100%;

        img {
            max-width: 100%;
        }
    }

.promotion-products {
    .promotion-product-option {
        .clearfix;

        .promotion-product.product-list {
            margin: 10px 0;
        }

        .attribute-choice option.partial-match {
            color: @colorLightText;
        }
    }
}

    .promotion-disclaimer {
        margin-top: 66px;
        text-align: left;
        float: none;
        clear: both;
        max-width: 100%;

        img {
            max-width: 100%;
        }
    }

    .product-bundle {
        .product-summary {
            clear: both;
        }
    }

    .ship-to-multiple-row {
        .make-row();
    }

    .ship-to-multiple-col {
        .make-sm-column-offset(3);
        .make-sm-column(4);
    }

    .history-list {
        .history-image {
            img {
                height: 100px;
            }
        }

        .select-all-row {
            .make-row();
        }

        .history-row {
            .make-row();
            margin-bottom: 20px;
        }

        .browsing-history-checkbox {
            .make-xs-column(1);
        }
    }

    .orderline-row.row {
        margin-bottom: 15px;
    }

    .popover {
        z-index: 5000;
        max-width: 330px;
    }

    .account-number-help-content {
        img {
            padding: 15px 0;
        }
    }

    #payment_method_div {
        h4 {
            clear: both;
        }
    }

    .zone-aside-first, .zone-aside-second {
        .product-list-widget-title {
            text-align: center;

            h3 {
                margin-top: 10px;
                margin-left: 0;
                margin-right: 0;
            }
        }


        .carousel {
            .item {
                text-align: center;

                .product-title {
                    text-align: center;
                    float: none;
                }
            }

            .carousel-control {
                width: 15%;
            }

            .carousel-indicators {
                bottom: -20px;
            }
        }
    }

    .auto-renew-enrollment {
        .make-row();

        .auto-renew-enrollment-form {
            .make-sm-column(4);
            .list-box;
            margin-left: 15px;

            .enroll {
                button {
                    .full-width;
                }
            }
        }
    }

    .confirm-renew-changes {
        border: @boxBorders;
        box-shadow: @boxShadows;
        margin: 30px;
        padding: 30px;
        text-align: center;

        .btn {
            margin-top: 30px;
            width: 300px;
        }
    }


    .csc-help-content {
        padding: 10px;
        text-align: center;
    }

    .csc-help-content-image {
        padding: 10px;
        text-align: center;
    }

    .csc-code {
        width: 25%;
        float: left;
        margin-right: 10px;
        min-width: 120px;
    }

    .csc-title-row {
        .make-row();
    }

    .csc-title-col {
        .make-sm-column(10);
    }

    .promotion legend {
        padding-top: 20px;
    }

    .account-fieldset {
        width: 100%;
    }

    .accountBoxMargin {
    }

    .address-return-col {
        .make-sm-column(3);
    }

    .submit-bill-to-address {
        .make-sm-column(4);
    }

    .account-orderline-row, .account-bill-to-row,
    .account-subscription-row {
        .make-row();
    }

    .account-orderline-col, .account-bill-to-col,
    .account-subscription-col, .account-page-col {
        .make-sm-column(12);
    }

    .cancel-subscription-row {
        .make-row();
        margin-bottom: 5px;

        .cancellation-col, .confirm-cancellation-col {
            .make-md-column(4);
        }
    }

    .account-box {
        .clearfix;

        i {
            color: @ciderButtonsAndLinks;
        }

        h2 {
            margin-bottom: 30px;
        }

        .account-snapshot {
            border: @boxBorders;
            box-shadow: @boxShadows;
            margin-bottom: 30px;
            .accountBoxMargin;

            h1 {
                margin: 20px;
            }

            .account-snapshot-groups {
                .make-row();
                margin-bottom: 15px;

                .account-snapshot-group {
                    .make-sm-column(3);
                    padding: 0 15px;

                    ol {
                        padding: 0;
                        margin: 0;
                    }

                    &.first {
                        text-align: center;
                        font-weight: bold;
                    }

                    &.second {
                        font-weight: bold;
                    }

                    &.third {
                        .make-sm-column(6);
                    }
                }
            }
        }

        ol {
            list-style: none;
            padding-left: 25px;

            li {
                padding: 4px;
            }
        }

        ul {
            list-style: none;
            padding-left: 0;

            li {
                padding: 4px;
            }
        }

        .my-account-gear {
            .make-xs-column(1);
            margin-left: -6px;
            padding-top: 3px;
        }

        .my-account-gear-title {
            .make-xs-column(11);
            margin-left: -20px;
            margin-bottom: 15px;
        }
    }

    .my-account-widget-container {
        .make-row();

        .my-account-menu-widget {
            .make-sm-column(4);

            .widget-ViewMyActivity {
                .my-account-widget;

                h3:before {
                    font-family: 'FontAwesome';
                    content: '\f080';
                }
            }

            .widget-ManageMyAccount {
                .my-account-widget;

                h3:before {
                    font-family: 'FontAwesome';
                    content: '\f085';
                }
            }

            .widget-UpdateMyPreferences {
                .my-account-widget;

                h3:before {
                    font-family: 'FontAwesome';
                    content: '\f0ad';
                }
            }

            .my-account-widget {
                margin-bottom: 30px;
                border: @boxBorders;
                box-shadow: @boxShadows;
                padding-left: 20px;
                .clearfix;

                h3 {
                    margin-top: 20px;

                    &:before {
                        color: @ciderButtonsAndLinks;
                        padding: 6px;
                        font-size: 24px;
                    }
                }

                ul {
                    list-style: none;
                    padding-left: 35px;
                    padding-bottom: 20px;

                    li {
                        padding: 4px;
                        width: 100%;

                        a {
                            padding: 0;

                            &:hover {
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    .account-popup-content {
        padding: 20px;

        .view-reciept-button {
            width: 100%;
            margin: 20px 0 0 0;
        }
    }

    .social-icon {
        font-size: 32px;
        margin: 10px 0;
    }

    .mini-cart {
        padding: 20px;

        .spinner:before {
            margin: 0;
        }

        .mini-cart-title {
            margin-bottom: 5px;
            text-align: left;

            h1 {
                margin: 0;
                color: @ciderButtonsAndLinks;
            }
        }

        .mini-cart-summary {
            padding-right: 10px;
            text-align: right;
            float: right;
            width: 100%;
            padding-top: 10px;
            border-top: @horizontalSeparator;
            margin-top: 0;
        }

        .empty-cart-text {
            text-align: center;
            margin-top: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            border-top: @horizontalSeparator;
            border-bottom: @horizontalSeparator;
        }

        .mini-cart-notifier {
            padding: 5px;
        }

        .mini-cart-item {
            .mini-cart-image {
                float: left;
                margin-left: 30px;
                margin-right: 10px;

                img {
                    height: 155px;
                    float: left;
                }
            }

            .mini-cart-info {
                margin-left: 10px;
                margin-right: 30px;

                h3 {
                    margin-top: 0;
                    overflow: hidden;
                    max-height: 80px;
                }
            }
        }

        .mini-cart-options {
            .make-row();

            .mini-cart-view, .mini-cart-checkout {
                .make-xs-column(6);
            }
        }
    }

    .download-row {
        .make-row();
    }

    .download-col {
        .make-md-column(12);
    }

    .download-links {
        padding-left: 10px;
    }

    .debit-spacer {
        height: 30px;
    }

    .spacer {
        height: 40px;
    }

    .new-cc-expire-row {
        .make-row();
    }

    .new-cc-expire-col {
        .make-xs-column(6);
    }

    .expire {
        select {
            padding: 5px;
        }

        .expire-month {
            padding-right: 5px;
        }

        .expire-year {
            padding-left: 5px;
        }
    }

    .input-group .form-control:first-child {
        border-radius: 4px;
    }

    .custom-form {
        .custom-form-field {
            .make-row();

            .custom-form-label {
                .make-sm-column(3);

                label {
                    margin-top: 10px;
                }
            }

            .custom-form-control {
                .make-sm-column(6);
                margin-bottom: 7px;

                &.no-label {
                    .make-sm-column-offset(3);
                }
            }

            .custom-form-validation-message {
                .make-lg-column(12);
            }

            .custom-form-hint {
                .make-sm-column-offset(3);
                .make-sm-column(6);
                font-size: 85%;
                margin-top: -5px;
                margin-bottom: 10px;
            }
        }
    }

    .custom-form-radio-button {
        input {
            margin: 3px;
        }
    }

    .custom-form-control {
        margin-bottom: 7px;

        & > label, & > input, & > label > input {
            padding: 5px;
            margin-top: 3px;
            margin-right: 2px;
        }
    }

    .custom-form-submit-col {
        .make-sm-column(3);
        .make-sm-column-offset(3);
    }

    .button {
        cursor: pointer;
    }

    .email-list-signup {
        .make-row();

        .email-list-text {
            .make-md-column-offset(2);
            .make-md-column(4);
        }

        .email-list-textbox {
            .make-md-column(2);
        }

        .email-list-subscribe {
            .make-md-column(2);
        }
    }

    .email-list-footer {
        .make-row();

        .email-list-footer-text {
            .make-md-column(12);
        }
    }

    .email-list-interstitial-row {
        .make-row();

        .email-list-interstitial-text {
            .make-md-column(12);
        }

        .email-list-interstitial-footer-text {
            .make-md-column(12);
        }

        .email-list-interstitial-textbox {
            .make-sm-column(6);
            .make-sm-column-offset(2);
        }

        .email-list-interstitial-subscribe {
            .make-sm-column(4);
        }
    }

    .email-list-interstitial {
        position: fixed;
        top: 50%;
        outline: none;
    }

    #save-email-list-list-changes {
        margin-top: 25px;
    }

    .modal-header {
        border: none;
    }

    .suspend-subscription-fields {
        .suspension-reason-field, .confirm-suspension {
            .make-md-column(4);
        }

        .suspension-datepicker {
            .make-md-column(2);
        }
    }

    .subscription-row {
        .make-row();

        .subscription-col {
            .make-sm-column(10);
        }
    }

    .subscription-item-row {
        .make-row();
    }

    .subscription-checkout-details-col,
    .subscription-checkout-participants-col {
        .make-md-column(6);
    }

    .confirm-participants-col {
        .make-sm-column(3);
        .make-sm-column-offset(9);
    }

    .confirm-questionnaire {
        .make-sm-column(3);
        .make-sm-column-offset(9);
    }

    .subscription-details-col {
        .make-sm-column(8);
    }

.edit-participants-row{
    .make-row();
    margin-bottom: 5px;
    margin-left: -5px;
    margin-right: -5px;
}
.subscription-service-col {
    .make-sm-column(4);
}

.participant-email-col, .participant-name-col {
    .make-sm-column(5);
    padding-left: 5px;
    padding-right: 5px;
}

.participant-controls-col {
    .make-sm-column(2);
    padding-left: 5px;
    padding-right: 5px;
}

.agreement-item-row, .subscription-expansion-row {
    .make-row();
}

.agreement-header{
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8e8e8;
}

.agreement-details-col {
    .make-sm-column(8);
}

.agreement-service-col {
    .make-sm-column(4);
}

.subscription-group {
    padding: 15px;
    margin-bottom: 15px;
    overflow: hidden;
    border: solid @colorBorder 1px;
    border-radius: 5px;
    box-shadow: @boxShadows;

        .subscription-item, .agreement-item {
            .unselectable {
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            .expansion-billing-col, .expansion-shipping-col,
            .expansion-participants-col {
                .make-sm-column(4);
            }

            a.renewal-offers {
                .unselectable;
                cursor: pointer;
            }

            a.additional-info {
                .unselectable;
                cursor: pointer;
            }
        }

        .renewal-offers-form {
            padding-left: 15px;

            .btn {
                &.renewal-offer-button {
                    width: 100%;
                    white-space: normal;
                    margin: 0 0 5px 0;
                }
            }
        }
    }

    .auto-charged-subscriptions-modal-body {
        padding-top: 0px;
        padding-bottom: 5px;

        .selectable-subscription-body {
            margin-top: 15px;

            .selectable-subscription-heading {
                font-size: 18px;
                margin-bottom: 5px;
            }

            .selectable-subscription-group {
                padding: 10px;
                margin-bottom: 10px;
                overflow: hidden;
                border: solid @colorBorder 1px;
                border-radius: 5px;
                box-shadow: @boxShadows;
            }

            .selectable-subscription-checkbox {
                .make-xs-column(1);
                padding-right: 0;
                padding-left: 5px;
                height: 20px;

                input[type=checkbox] {
                    width: 16px;
                    height: 16px;
                }
            }

            .selectable-subscription-column {
                .make-xs-column(11);
                padding-left: 0;
            }

            .selectable-subscription-title {
                font-size: 14px;
            }

            .selectable-subscription-hint-text {
                color: gray;
                font-size: .9em;
            }

            .selectable-subscription-participants-group {
                font-size: .9em;

                .participants-expansion-row {
                    .make-row();
                }

                .participants-expansion-column {
                    .make-sm-column(12);
                }
            }
        }
    }

    .renewal-summary-row, .renewal-summary-line {
        .make-row();
    }

    .renewal-summary-col {
        .make-md-column-offset(8);
        .make-md-column(4);
    }

    .renewal-line-label, .renewal-line-amount {
        .make-xs-column(6);
    }

    .renewal-total-label {
        .make-xs-column(7);
    }

    .renewal-total-amount {
        .make-xs-column(5);
    }

    .privacy-settings {
        .privacy-settings-options {
            label {
                .make-xs-column(12);
                text-align: left;
                margin-bottom: 0;
            }
        }

        & > .vcard {
            .vcard {
                border: none;
                box-shadow: none;
                margin: 0;
                padding: 0;
                max-width: none;

                i, h3 {
                    color: @ciderButtonsAndLinks;
                }
            }
        }

        .disable-all-options {
            .make-xs-column(12);
            margin-bottom: 5px;

            label.checkbox {
                padding-top: 0;
            }
        }

        #save-privacy-settings {
            float: none;
            clear: both;
        }
    }

    .disabled {
        opacity: .5;
    }

    .multi-currency-widget {
        .currency-change-drop-down {
            border-color: @ciderButtonsAndLinks
        }

        .multi-currency-label {
            color: @ciderButtonsAndLinks;
        }
    }

    .checkout-chain-space {
        clear: left;
    }

    .confirm-shipping-option {
        .make-sm-column-offset(9);
        .make-sm-column(3);
    }

    .navbar {
        .widget-search-form {
            float: left;
            width: 18%;
            margin-top: 18px;
        }
    }

    .label-text-align {
        text-align: right;
    }

    .mobile-full-height {
        height: auto;
    }

    label.checkbox, .checkbox-label, label.forcheckbox {
        cursor: pointer;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }

    .rightArrow, .leftArrow {
        top: 45%;
    }

    .email-unsubscribe {
        .email-unsubscribe-prompt {
            margin-bottom: 25px;
        }
    }

    .unsubscribe-row {
        .make-row();
    }

    .unsubscribe-col {
        .make-sm-column(6);
        .make-sm-column-offset(3);
    }

    .print-button {
        color: @colorBodyText;

        &:hover {
            color: @colorBodyText;
        }
    }

    @media print {
        a[href]:after {
            content: none;
        }
    }

    .open-invoices-page {
        .page-title {
            border-bottom: none;
            padding-bottom: 0;

            .title-row {
                .make-row();

                .title-col {
                    .make-sm-column(9);
                }

                .account-balance {
                    .make-sm-column(3);
                    margin-top: 20px;
                    font-weight: bold;
                }
            }
        }

        .pay-account-balance, .pay-open-invoice {
            width: 125px;
        }
    }

    .order-row, .order-detail-row {
        .make-row();
    }

    .order-col {
        .make-md-column(3);
    }

    .order-detail-col {
        .make-md-column(12);
    }

    .orderline-col {
        .make-md-column(6);
    }

    .orderline-actions {
        .make-md-column(3);
    }

    .orderline-offset {
        .make-md-column-offset(3);
    }

    .tracking-row {
        .make-row();

        div {
            margin-top: 2px;
        }
    }

    .shipping-method-col {
        .make-xs-column(6);
    }

    .ship-date-col, .tracking-url-col {
        .make-xs-column(2);
    }

    .invoice-apply-credit {
        width: 100%;
        margin-top: 15px;
    }

    .invoice-apply-credit-row {
        .make-row();
    }

    .invoice-apply-credit-col {
        .make-md-column(3);
        .make-md-column-offset(9);
    }

    .address-form, .account-registration-form, .customer-promote-settings-form, .submit-registration, form.form-slim {
        .horizontal-form-group {
            .make-row();
            //form-group not here because it is needed for jquery-unobtrusive validation javascript to work on the element
            .form-horizontal;
    
            .horizontal-form-label {
                .make-sm-column(3);
            }
    
            .horizontal-form-control {
                .make-sm-column-offset(3);
                .make-sm-column(4);
            }
    
            .horizontal-form-label + .horizontal-form-control {
                .make-sm-column-offset(0);
            }
    
            label {
                .control-label;
                margin-top: 7px;
                margin-bottom: 0;
            }
        }
    }

    .ship-to-gift-message-block {
        .add-gift-message-col {
            .make-sm-column-offset(3);
            padding-left: 0.6em;
        }

        .gift-message-form {
            .horizontal-form-group {
                .horizontal-form-control {
                    .make-sm-column-offset(3);
                    .make-sm-column(4);

                    .gift-message-textarea {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .gift-message-form {

        &#read-only-form {
            display: inline-block;
        }

        label {
            margin-bottom: 0;
        }

        .horizontal-form-group {
            .make-row();
            //form-group not here because it is needed for jquery-unobtrusive validation javascript to work on the element
            .form-horizontal;

            .horizontal-form-label {
                .make-sm-column(3);
            }

            .horizontal-form-control {
                .make-sm-column-offset(3);
                .make-sm-column(8);

                &.gift-message-type-dropdown {
                    select {
                        .full-width();
                    }
                }
            }

            .horizontal-form-label + .horizontal-form-control {
                .make-sm-column-offset(0);
            }

            .gift-message-button-group {
                .make-sm-column-offset(3);
                .make-sm-column(8);
                text-align: right;

                .btn:not(.btn-link) {
                    width: auto;
                }
            }

            label {
                .control-label;
                margin-top: 7px;
                margin-bottom: 0;
            }
        }

        .change-gift-message, .remove-gift-message {
            padding: 0;
        }
    }

    .gift-message-block {
        .add-gift-message {
            padding: 0;
        }
    }

    .update-gift-message {
        .add-gift-message {
            margin-left: 0;
            padding: 0;
        }
    }

    .modal-body {
        div {
            .address-form, .gift-message-form {
                .horizontal-form-group {
                    .horizontal-form-control {
                        .make-sm-column(7);
                    }
                }
            }

            .new-address-controls .address-control {
                .make-sm-column(7);
            }
        }
    }

    .checkout-address-page {
        .checkout-chain-space;

        .new-address-selector {
            .make-row();
            .form-horizontal .form-group;

            .selector-label {
                .make-md-column(3);
                text-align: right;

                label {
                    .control-label;
                    margin-top: 7px;
                    margin-bottom: 0;
                }
            }

            .selector {
                .make-md-column(4);
                .make-xs-column(12);
                .dual-radio-group;
            }
        }
    }

    .new-address-controls {
        .make-row();
        .form-group;

        .address-control {
            .make-sm-column(4);
            .make-sm-column-offset(3);

            .select-new-address {
                .full-width;
                margin-top: 20px;
            }
        }
    }


    input[type=number][step=any]::-webkit-inner-spin-button,
    input[type=number][step=any]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .questionnaire-choose-many-option {
        margin-left: 15px;
    }

    .promotion-product-list {
        .shipping-address-toggle-row .shipping-address-toggle-col, .actions .action {
            .make-sm-column-offset(3);
            .make-sm-column(4);
        }

        select.promotion-product-choice-selector {
            min-width: 250px;
        }
    }

    .promotion-renewal {
        .actions .action {
            .make-sm-column-offset(3);
            .make-sm-column(6);
        }
    }

    .promotion-product-list, .promotion-renewal {
        .actions {
            .make-row();

            .action {
                button.place-promo-order {
                    .full-width;
                    margin-top: 30px;
                }
            }
        }
    }

    form.account-activation {

        .account-activation-label {
            .make-md-column(3);
        }

        .account-activation-btn, .g-recaptcha {
            .make-md-column-offset(3);
        }

        .account-activation-field, .account-activation-btn, .g-recaptcha {
            .make-md-column(4);
        }
    }

    #accountNumberHelpButton {
        font-size: 12px;
        padding: 0 3px 3px 0;
    }

    .popover-row {
        .make-row();
    }

    .popover-title-col {
        .make-sm-column(10);
    }

    .close-popover-col {
        .make-sm-column(2);
    }

    .amount-to-pay-row, .confirm-payment-choice-row {
        .make-row();
    }

    .payment-choice-col {
        .make-md-column(3);
    }

    .invoice-summary-col {
        .make-md-column(3);
    }

    .invoice-summary-offset {
        .make-md-column-offset(6);
    }

    .invoice-summary-line {
        .make-row();

        .invoice-summary-label, .invoice-summary-amount {
            .make-xs-column(6);
        }

        .invoice-total-label {
            .make-xs-column(7);
        }

        .invoice-total-amount {
            .make-xs-column(5);
        }
    }

    .installment-billing-row {
        .make-row();
    }

    .installment-billing-details {
        .make-sm-column(3);
    }

    .payment-schedule-col {
        .make-sm-column(9);
    }

    .schedule-header-row, .scheduled-payment-row,
    .payment-info-row, .payment-header-row,
    .amount-received-row {
        .make-row();
    }

    .due-date-col, .amount-due-col, .amount-paid-col {
        .make-xs-column(4);
    }

    .payment-info-col {
        .make-sm-column(3);
    }

    .received-payments-col {
        .make-sm-column(9);

        .received-payment-row {
            .make-row();
        }

        .payment-description-col {
            .make-xs-column(5);
        }

        .payment-date-col {
            .make-xs-column(4);
        }

        .payment-amount-col {
            .make-xs-column(3);
        }
    }

    .amount-received-label {
        .make-xs-column(9);
    }

    .amount-received-col {
        .make-xs-column(3);
    }

    .invoice-payment-page, .renewal-payment-page {
        .invoice-summary {
            .make-row();
            margin-bottom: 20px;

            .invoice-payment-info {
                .make-md-column(4);
                .make-md-column-offset(2);
                padding-top: 40px;
            }
        }

        .invoice-payment-method {
            .make-row();

            .available-payment-options {
                .make-sm-column(4);
                .make-sm-column-offset(8);
                .list-box;
            }
        }

        .credit-payment {
            .make-row();

            .credit-payment-action {
                .make-md-column(4);
                .make-md-column-offset(8);
            }
        }
    }

    .open-auth-options {
        border-bottom: @horizontalSeparator;
        margin-bottom: 40px;

        div.open-auth-separator {
            margin-top: 20px;
            margin-bottom: -20px;
            border-radius: 100px;
            background-color: @ciderButtonsAndLinks;
            width: 40px;
            height: 40px;
            padding-top: 7px;
            color: #FFFFFF;
            font-size: 125%;
            text-align: center;
            margin-left: 45%;
        }
    }

    .new-registation-form {
        .address-form, .account-registration-form, .submit-registration {
            .horizontal-form-group .horizontal-form-control {
                .make-sm-column(5);
            }
        }

        .g-recaptcha {
            .make-sm-column(5);
            .make-sm-column-offset(3);
        }

        .g-recaptcha {
            .make-sm-column(5);
            .make-sm-column-offset(3);
        }
    }

    .change-password-text {
        .make-xs-column(12);
        .make-md-column(6);
    }

    .change-password-col {
        .make-md-column(6);
    }

    .lost-password-col {
        .make-md-column(6);
    }

    .change-login-label {
        .make-md-column(3);
    }

    .change-login-field {
        .make-md-column(4);
    }

    .save-login-changes {
        .make-md-column(4);
        .make-md-column-offset(3);
    }

    .social-login-col {
        .make-lg-column(3);
    }

    .social-login-links {
        .make-lg-column(9);
    }

    .login-row {
        .make-row();
    }

    .account-number-login {
        .make-sm-column(6);
    }

    .account-number-popover-title {
        .make-sm-column(10);
    }

    .close-account-number-popover {
        .make-sm-column(2);
    }

    .registration-other-options {
        form.open-auth-options {
            h3 {
                border-bottom: @horizontalSeparator;
                padding-bottom: 5px;
                margin-top: 0;
            }
        }
    }

    .product-renewal-offers {
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: @horizontalSeparator;
    }

    .input-validation-error {
        border-color: #a94442;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

        &:focus {
            border-color: #953b39;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
        }
    }

    .linked-premium-status {
        position: absolute;
        top: 8em;
        text-align: center;
        left: 0;
        width: 100%;
        color: @colorDarkText;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .linked-premium-header {
        margin-top: 20px;
        font-size: 16px;
    }

    .linked-premium-title {
        font-weight: bold;
    }

    .linked-premium-unearned {
        opacity: .3;
    }

    .linked-premium-image {
        position: relative;
        width: 100px;

        img {
            width: 100%;
        }
    }

    .linked-premium-row {
        .make-row();

        .linked-premium-item {
            .pull-left;
            margin: 15px;
        }
    }

    aside .widget-product-browse-widget {
        margin: 18px;

        .taxonomy-level, .product-browse-button {
            float: left;
            width: 100%;
        }
    }

    .conference-summary {
        .clearfix;
    }

    .conference-registration-row {
        .make-row();

        .conference-registration-col {
            .make-sm-column(10);

            .conference-registration-group {
                padding: 15px;
                margin-bottom: 15px;
                overflow: hidden;
                border: solid @colorBorder 1px;
                border-radius: 5px;
                box-shadow: 0 0 60px 0 rgba(0,0,0,.075);

                .conference-registration-item {
                    .unselectable {
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                    }

                    .conference-registration-item-row {
                        .make-row();

                        .conference-registration-details-col {
                            .make-sm-column(8);
                        }

                        .conference-registration-service-col {
                            .make-sm-column(4);
                        }
                    }

                    .expansion-billing-col, .expansion-attendees-col {
                        .make-sm-column(4);
                    }

                    .additional-info-row, .conference-registration-expansion-row {
                        .make-row();
                    }

                    .additional-info-col {
                        .make-sm-column(12);
                    }

                    a.additional-info {
                        .unselectable;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .manage-conference-attendees {
        .conference-attendee, .conference-attendee-labels {
            .make-row();
            float: none;
            clear: both;

            .attendee-name {
                .make-sm-column(2);
                margin-top: 5px;
            }

            .badge-name {
                .make-sm-column(3);
            }

            .controls {
                .make-sm-column(2);
                margin-top: 5px;

                a, button {
                    cursor: pointer;
                    margin-right: 15px;
                }
            }
        }

        .conference-attendee-labels {
            font-weight: bold;
            margin-top: 20px;
        }

        .form-action-container {
            .make-row();

            .action {
                .make-sm-column-offset(2);
                .make-sm-column(3);

                button {
                    .full-width;
                }
            }
        }
    }

    .popover-content {
        .options {
            margin-top: 15px;

            .confirm-remove-attendee {
                width: 47%;
                margin-right: 3%;
            }

            .cancel-remove-attendee {
                width: 47%;
            }
        }
    }

    .cancel-conference-registration-row {
        .make-row();
    }

    .panel-group {
        margin: 15px 0;

        .panel {
            a.panel-toggle {
                &:focus {
                    text-decoration: none;
                }

                &.collapsed .panel-heading:before {
                    font-family: 'FontAwesome';
                    content: '\f107';
                }
            }

            .panel-heading {
                background: #f5f5f5;

                &:before {
                    font-family: 'FontAwesome';
                    content: '\f106';
                    float: right;
                    font-size: 1.667em;
                    margin-top: -10px;
                    margin-bottom: -10px;
                }

                h4 {
                    margin-top: 0;
                    margin-bottom: 0;
                    font-size: 16px;

                    &:before {
                        content: '';
                    }
                }
            }

            &.complete .panel-heading h4:before {
                font-family: 'FontAwesome';
                content: '\f058';
                color: @ciderButtonsAndLinks;
                margin-right: 3px;
            }

            &.warn .panel-heading h4:before {
                font-family: 'FontAwesome';
                content: '\f06a';
                color: #F0C808;
                margin-right: 3px;
            }

            .panel-body {
                .session-time {
                    font-size: .9em;
                    color: gray;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .conference-locations-modal {
        .modal-dialog {
            max-width: 400px;
        }

        .conference-location-label {
            font-size: larger;
        }

        .conference-location-dates-modal {
            font-size: smaller;
        }
    }

    .cart-conference-opens {
        font-style: italic;
        font-size: 13px;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    aside .widget-product-browse-widget {
        margin: 18px;

        .taxonomy-level, .product-browse-button {
            float: left;
            width: 100%;
        }
    }

    article.content-item.association-level .association-level-item, .renew-membership-level-item {
        .list-box(15px);
    }

    .association-membership-additional-information {
        form.additional-information {
            .panel-group {
                .actions {
                    .action {
                        .btn {
                            .full-width;
                        }
                    }
                }

                #shipping-address {
                    .new-address-selector .horizontal-form-label {
                        .label-text-align;
                    }
                }
            }

            .horizontal-form-group {
                .horizontal-form-control {

                    .btn {
                        .full-width;
                    }
                }
            }
        }
    }

    .membership-service-heading {
        border-bottom: @boxBorders;
    }

    .membership-summary {
        .membership-summary-group {
            padding: 15px;
            margin-bottom: 15px;
            overflow: hidden;
            border: solid @colorBorder 1px;
            border-radius: 5px;
            box-shadow: @boxShadows;
        }
    }

    .membership-benefits-details {
        ul {
            li {
                .list-box;
                margin-bottom: 15px;
                padding: 15px;
            }
        }
    }

    .add-to-cart-donation-frequency {
        margin-bottom: 5px;
    }

    .add-to-cart-attribute-choice {
        margin-bottom: 5px;

        option.partial-match {
            color: @colorLightText;
        }
    }

    .add-to-cart-attribute-label {
        margin-bottom: 0;
    }

    .donation-preset-amounts {
        .clearfix;
        display: block;
    }

    .donation-custom-amount {
        display: inline-block;
        width: 100%;

        .donation-amount-group {
            .make-row();

            .donation-amount-field {
                .make-xs-column(7);
                padding-right: 2px;
            }

            .donation-amount-control {
                .make-xs-column(5);
                padding-left: 2px;

                button {
                    width: 100%;
                    padding: 6px 3px;
                }
            }
        }
    }

    .association-membership-donation-details ul.recurring-donations li.recurring-donation {
        .list-box;
        margin-bottom: 10px;
        padding: 15px 20px 20px 20px;

        div.change-recurring-donation-summary {
            font-size: 20px;
            color: @colorRegularParagraphText;
        }

        .cancel-recurring-donation {
            float: right;
            margin-right: -22px;
            margin-top: -10px;

            a.remove-donation {
                color: @alertRed;
                background-color: @colorButtonPrimaryText;
                border: 1px solid @alertRed;
                font-size: 1.2em;
                outline: none;
                margin: 0;
                padding: 5px 5px 5px 10px;
                border-radius: 0 0 0 16px;

                &:after {
                    font-family: 'FontAwesome';
                    content: "\f014";
                    margin: 0 5px 0 0px;
                }
            }
        }
    }

    ol.promotion-take-all, ol.promotion-pick-one, ol.promotion-pick-many {
        .no-promotion-items-message {
            .list-box;
            padding: 45px;
        }
    }
    /* TODO: Temporary for Dan's demo. We'll revisit Products to handle placement rules */
    article.association.content-item {
        .product-description-col-wide {
            p {
                margin-top: 0;
                margin-bottom: 20px;
            }
        }
    }

    .required:after {
        /* 
        HTML renders new lines as spaces. If an element has the required class, and the element's closing tag proceeds new line, e.g.
        <div class="required">
            <div>Mytext</div>
        </div>
        A space will be rendered between "Mytext" and the "*" we are adding to the after content. The output will be "Mytext *"
        
        If there is no new line, e.g.
        <div class="required"><div>Mytext</div></div>
        No space will be rendered. The output will be "Mytext*"
        Because of this, we add a space before the asterisk, and both forms will be rendered as "Mytext *"
    */
        content: " *";
        color: @alertRed;
    }

    .payment-method-action-button {
        font-size: 2em;
        padding-top: 0px;
    }

    .search-filter {
        margin-bottom: 5px;

        a.expander-control {
            color: @text-color;
            font-weight: bold;

            &.show-more-filters {
                padding-left: 12px;
            }
        }
    }

    .clear-category-icon {
        &:before {
            content: "\f104";
            font: 14px FontAwesome;
            width: unset;
            height: unset;
        }
    }

    .clear-filter-category {
        &, &:hover {
            color: @text-color;
        }
    }

    .filter-label {
        color: @text-muted;

        &:hover {
            color: @link-color;
        }

        &.selected {
            label {
                font-weight: bold;
            }
        }

        &.disabled .checkbox {
            label {
                cursor: default;

                input {
                    cursor: default;
                }
            }
        }

        .checkbox {
            margin: 5px 0;
        }
    }

    .faceted-search, .faceted-taxonomy {
        .make-row();

        .search-results {
            .make-sm-column(12);
            padding-left: 0;
            padding-right: 0;
        }

        .faceted-search-results, .faceted-product-category-results {

            &.filters-active {
                .make-sm-column(9);
            }

            .pager-info {
                .make-row();

                .result-count {
                    .make-sm-column(4);
                    margin-top: 6px;

                    .search-summary {
                        margin-top: 0;
                    }
                }

                .pager-settings {
                    .make-sm-column(8);

                    @media(max-width: @screen-sm) {
                        padding-left: 5px;
                        padding-right: 0px;
                        text-align: left;
                    }

                    .pager-settings-page-size {
                        display: inline-block;
                        width: auto;
                    }

                    .pager-settings-sorting {
                        display: inline-block;
                        width: auto;
                    }

                    text-align: right;
                    padding-right: 30px;

                    label {
                        margin-left: 10px;

                        @media(max-width: @screen-xs-max){
                            margin-left: 0;
                        }
                    }

                    .page-size-options, .sort-modes {
                        &.form-control {
                            display: inline-block;
                            width: auto;


                            @media (max-width: @screen-xs-max) {
                                max-width: 90px;
                                padding: 4px;
                            }
                        }
                    }
                }
            }

            &.filters-hidden .pager-info {
                margin-left: 0;
            }
        }

        .faceted-search-filters {
            .make-sm-column(3);
            max-width: 20%;
        }

        .clear-filters-btn {
            padding-bottom: 1px;
            padding-left: 0;

            .clear-filters-icon {
                float: none;
                padding-right: 0;

                &:before {
                    content: "\f104";
                    font: 14px FontAwesome;
                    width: unset;
                    height: unset;
                }
            }

            .clear-filters-text {
                margin-left: 5px;
            }
        }
    }

    .product-reviews {
        border-top: @boxBorders;
        border-bottom: @boxBorders;
    }

    .product-reviews-container {
        border-top: @boxBorders;
        margin-top: 30px;
    }

    .product-reviews-header {
        overflow: hidden;
    }

    .featherlight-iframe {
        cursor: default;

        .featherlight-content {
            overflow-y: auto;
            border-radius: 6px;
            vertical-align: inherit;
            margin-top: 30px;

            .featherlight-close {
                margin-right: 5px;
                margin-top: 5px;
            }
        }
    }

    .featherlight:last-of-type {
        background: #0008;
    }

    .card-capture-button {
        .full-width;
    }

    .change-renewal-settings-header {
        clear: both;
        margin-top: 80px;
    }

    .add-link, .remove-link {
        font-size: 85%;
        margin-right: 15px;
    }

    .add-link:before {
        font-family: 'FontAwesome';
        content: "\f067";
        margin-right: 0.25em;
    }

    .remove-link:before {
        font-family: 'FontAwesome';
        content: "\f00d";
        margin-right: 0.25em;
    }

    .address-selection-group {
        .horizontal-form-group {
            .horizontal-form-control {
                .make-sm-column(6);
            }
        }
    }

    .promotion-form {
        .choice-product-details-subtotal .additional-text {
            color: @ciderButtonsAndLinks;
        }
    }

    .cardholder-consent-flag-group {
        font-size: .9em;
        margin-top: 12px;
        margin-bottom: 5px;
        padding-top: 10px;
        border-top: solid 1px #eee;
    }

    .promotion-form {
        .choice-product-details-subtotal .additional-text {
            color: @ciderButtonsAndLinks;
        }
    }

    .cardholder-consent-flag-group {
        font-size: .9em;
        margin-top: 12px;
        margin-bottom: 5px;
        padding-top: 10px;
        border-top: solid 1px #eee;
    }

    .page-anchor-navigation(@topPadding: 0) {
        // Adjust margin and padding so page anchor navigation isn't blocked by the header
        @fixedHeaderHeight: 70px;
        padding-top: @fixedHeaderHeight + @topPadding;
        margin-top: -@fixedHeaderHeight;
    }

    .content-item.selectable-unit-package {
        .selectable-unit-package-number-to-select {
            .page-anchor-navigation(30px);
        }
    }

    .selectable-unit-package-modal {
        .modal-dialog {
            width: 800px;
        }
    }

    ul.selectable-unit-package-component-list {
        .make-row();
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin-top: 15px;

        li {
            .make-xs-column(6);
            .make-sm-column(4);
            .make-md-column(3);
            padding-bottom: 20px;

            &:hover {
                .package-component a.view-component-details {
                    display: block;
                    opacity: .85;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            input[type="checkbox"]:checked ~ .package-component {
                .selectable-item-selected(@colorHighlight);

                &:after {
                    content: '\f046';
                    margin-right: -3px;
                    color: @colorBodyText;
                }
            }

            label {
                display: block;
                height: 100%;

                input[type="checkbox"] {
                    display: none;
                }

                .package-component {
                    .selectable-item(@colorHighlight);
                    height: 100%;
                    display: block;
                    padding: 15px;
                    text-align: center;
                    user-select: none;

                    &:after {
                        font-family: 'FontAwesome';
                        content: '\f096';
                        font-size: 1.5em;
                        color: @colorLink;
                        position: absolute;
                        top: 0;
                        right: 20px;
                    }

                    a.view-component-details {
                        opacity: 0;
                        position: absolute;
                        left: 50%;
                        background: @colorBodyBackground;
                        border: 1px solid @colorButtonDefault;
                        color: @colorDarkText;
                        -webkit-transform: translateX(-50%);
                        transform: translateX(-50%);
                        -webkit-transition: opacity 0.25s linear;
                        -moz-transition: opacity 0.25s linear;
                        -o-transition: opacity 0.25s linear;
                        transition: opacity 0.25s linear;
                    }

                    img {
                        margin: auto;
                        -webkit-user-drag: none;
                        -khtml-user-drag: none;
                        -moz-user-drag: none;
                        -o-user-drag: none;
                        user-drag: none;
                    }
                }
            }
        }
    }

    a.edit-selectable-package-selection {
        white-space: nowrap;

        &:before {
            font-family: 'FontAwesome';
            content: '\f040';
            margin-right: .5em;
        }
    }

    .selectable-package-actions {
        .make-row();

        .selectable-package-add-to-cart {
            .make-sm-column(6);
            .make-sm-column-offset(3);

            button {
                .full-width;
            }
        }
    }

    a.back-to-cart, a.edit-selectable-package-selection {
        &:focus {
            text-decoration: none;
        }
    }

    a.back-to-cart {
        display: block;
        margin-bottom: 1em;

        &:before {
            font-family: 'FontAwesome';
            content: '\f053';
            margin-right: .5em;
        }
    }

    fieldset.extended-customer-values {
        padding-bottom: 30px;
    }

    .cardholder-consent-checkbox-label{
        font-weight:normal;
    }

#stripe-modal {
    .stripe-modal-title {
        margin-top: 5px;
        text-align: center;
        font-size: 27px;
        color: #333333;
    }

    #payment-element {
        margin-bottom: 15px;
    }

    #payment-form {
        padding: 10px 25px 15px 25px;
    }
}

#spreedly-modal-overlay{
    // Make sure Spreedly modal displays over checkout chain and navbar
    z-index: 2000;
}
